@import url("https://fonts.googleapis.com/css?family=Maven+Pro:400,500,700,900|Roboto+Condensed:300,300i,400,400i,700,700i");

body {
  background-color: var(--f9f9f9-color);
  font-size: 13px;
  font-family: "Maven Pro", sans-serif;
  line-height: 25px;
  color: var(--f888-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--f333-color);
}

p {
  font-size: 13px;
  line-height: 22px;
  color: var(--f888-color);
}

img {
  height: auto;
  max-width: 100%;
}

ul,
ol {
  list-style: outside none none;
}

ul {
  margin: 0;
  padding: 0;
}

img {
  transform-style: preserve-3d;
}

a {
  text-decoration: none !important;
  color: var(-f888-color);
  transition-duration: 0.4s;
}

a:hover {
  transition-duration: 0.4s;
  color: var(--f333-color);
}

a,
a:hover {
  text-decoration: none;
}

a:focus {
  outline: medium none;
  outline-offset: 0;
}

.border-none {
  border: medium none !important;
}

.section-padding {
  padding: 40px 0;
}

.margin-auto {
  margin: auto;
}

.dataTables_wrapper.container-fluid.dt-bootstrap4.no-footer {
  margin-top: 15px;
  padding: 0;
}

.help-block li {
  color: var(--red-color);
}

.bg-success {
  background: var(--success-color) none repeat scroll 0 0 !important;
}

.bg-dark {
  background: var(--black2224-color) none repeat scroll 0 0 !important;
}

.btn-secondary {
  background: var(--primary-color) none repeat scroll 0 0 !important;
}

.text-secondary {
  color: var(--primary-color) !important;
}

.btn-danger {
  background: var(--red-color) none repeat scroll 0 0 !important;
}

.text-danger {
  color: var(--primary-color) !important;
}

.badge {
  border-radius: 2px;
  font-weight: 400;
}

.btn {
  border: medium none;
  border-radius: 2px !important;
  font-size: 13px;
}

.heading-design-h5 {
  font-size: 18px;
  margin-bottom: 14px;
}

.heading-design-h5 a {
  font-size: 15px;
  line-height: 22px;
}

.border-top {
  border-top: 1px solid var(--eeeee-color) !important;
}

.border-bottom {
  border-bottom: 1px solid var(--eeeee-color) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--f9f9f9-color) !important;
  color: var(--black2224-color) !important;
  text-decoration: none;
}

.select2-container--default .select2-selection--single {
  background-color: var(--white-color);
  border: 1px solid var(--ced4da-color);
  border-radius: 2px;
  width: 100%;
}

.select2-container .select2-selection--single {
  height: 35px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 21px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0.375rem 0.75rem;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  right: 8px;
  top: 5px;
}

.checkout-step-two .select2-container {
  width: 100% !important;
}

.accordion .card:not(:first-of-type):not(:last-of-type),
.accordion .card:first-of-type {
  border: 1px solid var(--eeeee-color);
}

.select2-dropdown {
  border: medium none !important;
  border-radius: 0 !important;
  box-shadow: 0 4px 4px var(--dcdcdc-color) !important;
}

.owl-theme .owl-controls .owl-buttons div,
.footer-social a {
  transform: scale(1);
  transition-duration: 0.4s;
}

.owl-theme .owl-controls .owl-buttons div:hover,
.footer-social a:hover {
  transform: scale(1.09);
}

.input-group-text {
  border-radius: 2px;
}

.card-header {
  background-color: var(--fbfbfb-color);
  border-bottom: 1px solid var(--eeeee-color);
}

.card {
  background-clip: border-box;
  background-color: var(--white-color);
  border: 1px solid var(--eeeee-color);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  word-wrap: break-word;
}

.list-group-item:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.list-group-item:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.list-group-item {
  border: 1px solid var(--eeeee-color);
}

.navbar-nav li.dropdown:hover .dropdown-menu {
  display: block;
}

/* Top Menu */
.osahan-menu {
  padding: 0;
}

.osahan-menu .navbar-brand {
  padding: 0px 18px;
}

.osahan-menu .navbar-brand:hover {
  background: var(--f384042-color) none repeat scroll 0 0;
}

.main-nav-right .btn {
  border-radius: 0 !important;
  border-right: 1px solid var(--f384042-color);
  color: var(--white-color);
  margin: 0 0 0 -4px;
  padding: 24px;
}

.main-nav-right .btn:hover {
  background-color: var(--f384042-color) !important;
}

.main-nav-right li {
  margin: 0 !important;
}

.main-nav-right .osahan-top-dropdown img {
  border: 2px solid var(--white-color);
  border-radius: 50px;
  height: 32px;
  left: 20px;
  position: absolute;
  top: 18px;
  width: 32px;
}

.main-nav-right .osahan-top-dropdown .dropdown-toggle {
  padding-left: 65px;
}

.cart-btn .mdi {
  font-size: 20px;
  line-height: 0;
  margin-right: 5px;
  vertical-align: -3px;
}

.cart-btn .btn {
  font-weight: 500;
  position: relative;
}

.cart-btn .cart-value {
  background: var(--primary-color) none repeat scroll 0 0;
  border-radius: 50px;
  font-size: 10px;
  font-weight: 500;
  height: 18px;
  left: 31px;
  line-height: 17px;
  min-width: 18px;
  position: absolute;
  top: 11px;
}

.location-top {
  color: var(--white-color) !important;
}

.location-top i {
  font-size: 25px;
  vertical-align: sub;
}

.top-categories-search {
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 16px;
  width: 50%;
}

.top-categories-search .form-control {
  border: medium none;
  font-size: 13px;
  height: 38px;
  min-width: 384px;
}

.top-categories-search .btn {
  border-radius: 0 2px 2px 0 !important;
  font-weight: 500;
  height: 38px;
  padding: 0 21px;
  text-transform: uppercase;
}

.top-categories-search .select2-selection.select2-selection--single {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: -moz-use-text-color var(--ececec-color) -moz-use-text-color -moz-use-text-color;
  border-image: none;
  border-radius: 2px 0 0 2px;
  border-style: none solid none none;
  border-width: medium 1px medium medium;
  height: 38px;
}

.top-categories-search .form-control-select,
.top-categories-search .select2-container {
  width: 168px !important;
}

.top-categories-search
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  font-size: 13px;
  line-height: 36px;
  padding: 0 16px;
}

.top-categories-search
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 26px;
  position: absolute;
  right: 10px;
  top: 5px;
  width: 20px;
}

/* Link Menu */
.osahan-menu-2 {
  background: var(--white-color) none repeat scroll 0 0 !important;
  border-radius: 0;
  box-shadow: 0 2px 2px var(--ececec-color);
  margin-bottom: 15px;
  padding: 0;
}

.osahan-menu-2 .nav-link {
  color: var(--f888-color) !important;
  font-size: 14px;
  padding: 13px 16px !important;
}

.dropdown-menu {
  border: medium none;
  border-radius: 0 0 2px 2px;
  box-shadow: 0 4px 4px var(--dcdcdc-color);
  margin: 0;
}

.dropdown-menu .dropdown-item {
  color: var(--f888-color);
  font-size: 13px;
  padding: 5px 14px !important;
}

.osahan-menu-2 .nav-link.shop {
  border-right: 1px solid var(--ececec-color);
  color: var(--f333-color) !important;
  font-weight: 500;
  margin-right: 11px;
  padding-right: 22px !important;
}

.osahan-menu-2 .nav-link:hover {
  background: var(--fbfbfb-color) none repeat scroll 0 0;
  color: var(--f333-color) !important;
}

.category-item a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Category Slider */
.category-item img {
  background: var(--white-color) none repeat scroll 0 0;
  border-radius: 50px;
  box-shadow: 0 0 2px var(--eeeee-color);
  height: 80px;
  margin: 1px 0 8px;
  object-fit: scale-down;
  padding: 7px;
  transform: scale(1);
  transition-duration: 0.4s;
  width: 80px !important;
}

.category-item:hover img {
  transform: scale(1.09);
}

.category-item {
  padding: 8px;
  text-align: center;
}

.category-item h6 {
  font-size: 13px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.top-category {
  background: var(--white-color) none repeat scroll 0 0;
  border-bottom: 1px solid var(--eeeee-color);
  border-top: 1px solid var(--eeeee-color);
  margin-bottom: 15px;
  padding: 14px 0;
}

.category-item p {
  margin: 0;
}

.top-category .owl-prev {
  left: -70px;
}

.top-category .owl-next {
  right: -70px;
}

/* Main Slider */
.carousel-slider-main .owl-pagination {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  display: inline-flex;
  justify-content: center;
}

.carousel-slider-main {
  position: relative;
}

.carousel-slider-main .owl-prev,
.carousel-slider-main .owl-next {
  top: 46%;
}

/* Featuvar(--red-color) Product */
.owl-carousel-featured {
  border-right: 1px solid var(--eeeee-color);
}

.product-header .badge {
  background: var(--fbfbfb-color) none repeat scroll 0 0;
  border: 1px solid var(--success-color);
  border-radius: 2px;
  color: var(--success-color);
  font-size: 12px;
  font-weight: 500;
  left: 20px;
  padding: 4px 10px;
  position: absolute;
  top: 20px;
  z-index: 9;
}

.product {
  background: var(--white-color) none repeat scroll 0 0;
  border: 1px solid var(--eeeee-color);
  border-radius: 2px;
  margin: 0 3px 0px 0px;
  padding: 15px;
  position: relative;
}

.product-header .mdi-circle {
  border: 1px solid;
  border-radius: 2px;
  display: inline-block;
  font-size: 11px;
  height: 22px;
  line-height: 19px;
  position: absolute;
  right: 20px;
  text-align: center;
  top: 20px;
  width: 22px;
}

.product-body h5 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.product-body h6 {
  color: var(--ced4da-color);
  font-size: 12px;
  margin: 5px 0 14px;
}

.product-header img {
  height: 200px;
  margin: 0 0 18px;
  object-fit: scale-down;
  transform: scale(1);
  transition-duration: 0.4s;
}

.product:hover .product-header img {
  transform: scale(1.09);
}

.product-header {
  text-align: center;
}

.offer-price {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}

.regular-price {
  color: var(--f888-color) !important;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  text-decoration: line-through;
}

.product-footer .btn {
  border-radius: 49px !important;
  font-size: 12px;
  padding: 6px 12px;
}

.owl-carousel-featured .owl-prev {
  left: -40px;
  top: 43%;
}

.owl-carousel-featured .owl-next {
  right: -40px;
  top: 43%;
}

/* Featuvar(--red-color) Box */
.feature-box p {
  margin: 0;
}

.feature-box h6 {
  font-size: 15px;
  font-weight: 200;
  margin: 0;
  padding: 10px 0 0;
}

.feature-box .mdi {
  border: 1px solid var(--ced4da-color);
  border-radius: 50px;
  color: var(--ced4da-color);
  display: inline-block;
  font-size: 21px;
  height: 60px;
  line-height: 56px;
  margin: 0 15px 0 0;
  text-align: center;
  width: 60px;
}

/* Footer */
.footer {
  border-bottom: 1px solid var(--eeeee-color);
  border-top: 1px solid var(--eeeee-color) !important;
}

.app img {
  height: 32px;
  margin: 0 11px 0 0;
}

.footer-bottom p {
  line-height: 16px;
}

.footer-social a {
  border-radius: 25px;
  color: var(--white-color);
  display: inline-block;
  height: 27px;
  line-height: 26px;
  text-align: center;
  width: 27px;
}

.footer-social2 a {
  color: var(--primary-color) !important;
}

/* Cart Sidebar */
body.toggled {
  overflow: hidden;
}

body.toggled::after {
  background: var(--black-color) none repeat scroll 0 0;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.7;
  position: absolute;
  right: 0;
  z-index: 190 !important;
  top: 0;
}

.cart-sidebar {
  background: var(--white-color) none repeat scroll 0 0;
  overflow: hidden;
  position: fixed;
  right: -400px;
  top: 0;
  width: 400px;
  z-index: 199 !important;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  height: 100vh;
}

.toggled .cart-sidebar {
  right: 0px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.cart-sidebar-body {
  height: 66vh;
  overflow: auto;
}

.cart-sidebar-header {
  background: var(--black2224-color) none repeat scroll 0 0;
  color: var(--white-color);
  padding: 18px 20px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.cart-sidebar-header2 {
  position: static !important;
}

.cart-sidebar-header h5 {
  color: var(--white-color);
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.cart-sidebar-header .float-right {
  background: var(--white-color) none repeat scroll 0 0;
  border-radius: 24px;
  color: var(--black-color);
  height: 26px;
  line-height: 25px;
  text-align: center;
  width: 26px;
}

.cart-list-product {
  border-bottom: 1px solid var(--ececec-color);
  overflow: hidden;
  padding: 14px 20px;
  position: relative;
}

.cart-list-product img {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: var(--ececec-color) var(--ececec-color) var(--dcdcdc-color);
  border-image: none;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px 1px 3px;
  box-shadow: 0 0 3px var(--ececec-color);
  float: left;
  height: 99px;
  margin: 0 15px 0 0;
  object-fit: scale-down;
  width: 82px;
}

.cart-list-product h5 a {
  font-size: 14px;
}

.cart-list-product h5 {
  margin: 0;
}

.cart-list-product h6 {
  font-size: 11px;
}

.cart-list-product > h6 span {
  color: var(--primary-color);
}

.remove-cart {
  position: absolute;
  right: 18px;
  top: 12px;
}

.cart-list-product .badge {
  background: var(--fbfbfb-color) none repeat scroll 0 0;
  border: 1px solid var(--success-color);
  border-radius: 2px;
  color: var(--success-color);
  font-size: 11px;
  font-weight: 500;
  padding: 3px 6px;
}

.cart-sidebar-footer {
  background: var(--ececec-color) none repeat scroll 0 0;
  padding: 10px 20px;
  position: sticky;
  bottom: 0;
}

.cart-store-details p {
  margin: 0 0 3px;
}

.cart-store-details h6 {
  margin: 10px 0 19px;
}

.cart-sidebar-footer .btn {
  padding: 15px 17px;
}

/* Shop Filters */
.shop-filters .card-header .btn {
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 500;
  padding: 7px 0;
  position: relative;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

.shop-filters .card-header .btn .mdi {
  position: absolute;
  right: 0;
}

.shop-filters .card-body {
  padding: 5px 0px 5px 18px;
}

.color_select_color {
  color: var(--primary-color) !important;
}

.card-shop-filters .custom-control .badge {
  float: right;
  margin: 6px 0 0;
}

.card-shop-filters .custom-control label {
  width: 100%;
}

.list-group-item.active {
  background: var(--primary-color) none repeat scroll 0 0;
  border-color: var(--primary-color);
}

.shop-filters .card {
  margin: -2px 0 0;
}

/* Shop Detail */
.shop-detail-slider #sync1 {
  border: 1px solid var(--eeeee-color);
  border-radius: 12px;
  margin-bottom: 15px;
  overflow: hidden;
}

.shop-detail-slider {
  position: relative;
}

.favourite-icon a {
  background: var(--red-color) none repeat scroll 0 0;
  border-radius: 50px;
  color: var(--white-color);
  font-size: 17px;
  height: 35px;
  line-height: 34px;
  position: absolute;
  right: 20px;
  text-align: center;
  top: 20px;
  width: 35px;
  z-index: 9;
}

.shop-detail-slider #sync2 img {
  border: 2px solid var(--eeeee-color);
  border-radius: 12px;
  cursor: pointer;
  margin: 26px 0 8px;
  width: 67px;
}

.shop-detail-slider #sync2 .item {
  text-align: center;
}

.shop-detail-right {
  background: var(--white-color) none repeat scroll 0 0;
  border: 1px solid var(--eeeee-color);
  border-radius: 12px;
  padding: 32px;
}

.shop-detail-slider #sync2 .synced img,
.shop-detail-slider #sync2 img:hover {
  border-color: var(--black2224-color);
}

.shop-detail-slider #sync2 {
  padding: 0 70px;
}

.shop-detail-right .badge {
  background: var(--fbfbfb-color) none repeat scroll 0 0;
  border: 1px solid var(--success-color);
  border-radius: 2px;
  color: var(--success-color);
  font-size: 14px;
  font-weight: 500;
  padding: 6px 13px;
}

.shop-detail-right h2 {
  font-size: 24px;
  font-weight: 100;
  margin: 10px 0 5px;
}

.shop-detail-right > h6 {
  color: var(--f888-color);
  font-size: 14px;
  margin: 0 0 19px;
}

.shop-detail-right .btn {
  font-weight: 500;
  margin: 29px 0;
  padding: 15px 50px;
  text-transform: uppercase;
}

.short-description {
  background: var(--white-color) none repeat scroll 0 0;
  border: 1px solid var(--eeeee-color);
  border-radius: 2px;
  padding: 22px;
}

.shop-detail-right .regular-price {
  font-size: 17px;
  font-weight: 100;
  margin: 0 0 8px;
  color: var(--primary-color) !important;
}

.shop-detail-right .offer-price {
  font-size: 20px;
  font-weight: 300;
}

.shop-detail-right .offer-price .text-success {
  font-size: 30px;
  font-weight: 500;
}

.shop-detail-right .feature-box .mdi {
  background: var(--primary-color) none repeat scroll 0 0;
  border: medium none;
  color: var(--white-color);
}

.shop-detail-right .feature-box h6 {
  font-weight: 400;
}

.shop-detail-right .feature-box {
  margin: 4px 0;
}

/* Cart Page */
.cart_product img {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: var(--ececec-color) var(--ececec-color) var(--dcdcdc-color);
  border-image: none;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px 1px 3px;
  box-shadow: 0 0 3px var(--ececec-color);
  float: left;
  height: 88px;
  margin: 0px;
  object-fit: scale-down;
  width: 72px;
}

.cart_summary td {
  vertical-align: middle;
}

.cart_summary > thead,
.cart_summary > tfoot {
  background: var(--fbfbfb-color) none repeat scroll 0 0;
}

.cart_summary {
  border: medium none !important;
}

.qty .form-control {
  border-radius: 2px !important;
  margin: 0 2px;
  text-align: center;
  width: 18px;
}

.qty .btn {
  background: var(--f888-color) none repeat scroll 0 0;
  border-radius: 2px !important;
  color: var(--white-color);
  font-size: 23px;
  height: 33px;
  line-height: 15px;
  padding: 0;
  text-align: center !important;
  vertical-align: baseline;
  width: 27px;
}

.cart_description h5 {
  font-size: 15px;
  margin: 0 0 5px;
}

.cart_description h6 {
  font-size: 12px;
  font-weight: 100;
}

.availability .badge {
  font-size: 11px;
  padding: 6px 11px;
}

.cart-table {
  border-top: medium none;
  padding: 0;
}

.cart-table .table {
  margin-bottom: 0;
}

.cart-table .btn-secondary {
  border-radius: 0 !important;
  font-size: 16px;
  padding: 20px;
  text-transform: uppercase;
}

.cart_product {
  width: 10%;
}

/* Checkout Page */
.checkout-step .card {
  margin: 0 0 -1px;
}

.checkout-step .card .card-header .btn {
  color: var(--f333-color);
  font-size: 17px;
  font-weight: 500;
  padding: 9px 0;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

.checkout-step .card .card-header .btn .number {
  border-radius: 50px;
  color: var(--white-color);
  display: inline-block;
  font-weight: bold;
  height: 34px;
  line-height: 28px;
  margin: 0 8px 0 0;
  text-align: center;
  text-decoration: none;
  vertical-align: 0;
  width: 34px;
  border: 2px solid;
}

.checkout-step .card .card-header .btn.collapsed .number {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.checkout-step .card .card-header .btn .number {
  border: 2px solid var(--success-color);
  color: var(--success-color);
}

.order-done .mdi {
  font-size: 135px;
  line-height: 161px;
}

/* Login Modal */

.form-control {
  border-radius: 2px;
  font-size: 14px;
}

label {
  font-size: 13px;
  margin: 0 0 3px;
}

.modal-content {
  border: medium none;
  border-radius: 2px;
}

.login-modal-right {
  padding: 27px;
}

.login-icons {
  border: medium none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.login-footer-tab .nav-link {
  background: var(--ececec-color) none repeat scroll 0 0;
  border: medium none;
  border-radius: 2px !important;
  margin: 10px 3px 0 2px;
  padding: 7px 20px;
  color: var(--f888-color);
}

.login-footer-tab .nav {
  border: medium none;
  display: inline-flex;
}

.btn-facebook {
  background: var(--facebook-color) none repeat scroll 0 0;
  color: var(--white-color);
}

.btn-google {
  background: var(--google-color) none repeat scroll 0 0;
  color: var(--white-color);
}

.btn-twitter {
  background: var(--twitter-color) none repeat scroll 0 0;
  color: var(--white-color);
}

.btn-instagram {
  background: var(--instramm-color) none repeat scroll 0 0;
  color: var(--white-color);
}

.btn-whatsapp {
  background: var(--whatapp-color) none repeat scroll 0 0;
  color: var(--white-color);
}

.login-with-sites p {
  margin: 0 0 7px;
}

.login-with-sites {
  margin-bottom: 10px;
}

.modal-backdrop.show {
  opacity: 0.7;
}

/* My Account */
.account-page .mx-auto {
  background: var(--white-color) none repeat scroll 0 0;
  border: 1px solid var(--eeeee-color);
  padding: 0;
}

.account-page .mx-auto .card {
  border: medium none;
}

.account-right {
  border-left: 1px solid var(--eeeee-color) !important;
  min-height: 508px;
}

.user-profile-header img {
  border-radius: 50px;
  height: 100px;
  margin-bottom: 15px;
  object-fit: scale-down;
  width: 100px;
}

.user-profile-header {
  padding: 36px;
  text-align: center;
}

.user-profile-header p {
  margin: 0;
}

.account-left {
  margin-right: -1px;
}

.account-left .list-group {
  padding: 10px;
}

/* About Page */
.team-card img {
  border-radius: 50px;
  height: 100px;
  width: 100px;
}

/* Blog Page */
.blog .card-body,
.sidebar-card .card-body,
.reviews-card .card-body {
  padding: 25px;
}

.blog-header {
  border: 2px solid var(--white-color);
}

.sidebar-card-list li a {
  border-bottom: 1px solid var(--fbfbfb-color);
  display: inline-block;
  padding: 8px 0;
  width: 100%;
}

.sidebar-card-list li:last-child a {
  border: medium none;
  padding-bottom: 0;
}

.tag-cloud-link {
  background: var(--eeeee-color) none repeat scroll 0 0;
  border-radius: 2px;
  color: var(--white-color);
  display: inline-block;
  margin: 2px 0;
  padding: 4px 13px;
}

.tag-cloud-link:hover {
  background: var(--black-color) none repeat scroll 0 0;
  color: var(--white-color);
}

.blog .card-title a {
  color: var(--f333-color) !important;
}

.blog .card-title {
  margin: 0 0 7px;
}

.entry-meta {
  margin-bottom: 13px;
}

.blog .card-body a {
  color: var(--primary-color);
}

.entry-meta a {
  color: var(--ced4da-color) !important;
}

.entry-meta .mdi {
  background: var(--ced4da-color) none repeat scroll 0 0;
  border-radius: 12px;
  color: var(--white-color);
  display: inline-block;
  font-size: 11px;
  height: 19px;
  line-height: 19px;
  text-align: center;
  width: 19px;
}

.reviews-card .d-flex {
  height: 50px;
  width: 50px;
}

.reviews-card h5 small {
  color: var(--f888-color);
  font-size: 12px;
  margin: 0 0 0 8px;
}

.star-rating i {
  font-size: 15px;
  letter-spacing: -6px;
}

/* Mobile Media */
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .carousel-slider-main .owl-prev,
  .carousel-slider-main .owl-next {
    top: 34%;
  }

  .account-left {
    margin-right: 0;
  }

  .account-page .mx-auto {
    margin: 0 15px !important;
  }

  .account-right {
    border-left: medium none !important;
    border-top: 1px solid var(--eeeee-color) !important;
    min-height: auto !important;
  }

  .location-top {
    display: none;
  }

  .top-categories-search {
    left: 0;
    margin: auto;
    padding: 0 15px 8px;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
  }

  .container {
    max-width: 100%;
  }

  .main-nav-right .osahan-top-dropdown .dropdown-toggle {
    padding: 18px 16px !important;
  }

  .main-nav-right .osahan-top-dropdown img {
    left: auto;
    position: relative;
    top: 0;
  }

  .osahan-menu-2 .nav-link.shop {
    border-right: medium none;
    margin-right: 0;
  }

  .shop-detail-slider #sync2 {
    padding: 0;
  }

  .shop-detail-slider #sync2 img {
    margin: 26px 0 10px 0;
  }

  .navbar-toggler {
    background: var(--white-color) none repeat scroll 0 0 !important;
    border: medium none;
    border-radius: 2px;
    margin: 0 16px 0 0;
    padding: 6px 9px;
  }

  .top-categories-search-main {
    margin-top: 0 !important;
  }

  .main-nav-right .btn {
    font-size: 0;
    padding: 21px 15px;
  }

  .osahan-menu .my-2.my-lg-0 .main-nav-right {
    position: absolute;
    right: 76px;
    top: 0;
  }

  .main-nav-right .btn .mdi {
    font-size: 17px !important;
    line-height: unset !important;
    margin: 0;
    vertical-align: initial !important;
  }

  .login-modal-left {
    display: none;
  }

  .app img {
    margin-bottom: 4px;
  }

  .footer-social a {
    margin-bottom: 3px;
  }

  .top-categories-search .form-control {
    border-radius: 2px 0 0 2px !important;
    min-width: auto;
  }

  .cart-sidebar {
    width: 320px;
  }

  .login-modal-right {
    padding: 6px;
  }

  .top-category .owl-prev {
    left: 0;
  }

  .top-category .owl-next {
    right: 0px;
  }

  .owl-carousel-featured .owl-prev {
    left: 0;
  }

  .owl-carousel-featured .owl-next {
    right: 0px;
  }

  .feature-box {
    margin-bottom: 6px;
    overflow: hidden;
  }

  .footer h6 {
    margin-bottom: 12px !important;
    margin-top: 25px;
  }

  .top-categories-search .form-control-select,
  .top-categories-search .select2-container {
    width: 138px !important;
    display: none;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .carousel-slider-main .owl-prev,
  .carousel-slider-main .owl-next {
    top: 40%;
  }

  .account-left {
    margin-right: 0;
  }

  .account-page .mx-auto {
    margin: 0 15px !important;
  }

  .account-right {
    border-left: medium none !important;
    border-top: 1px solid var(--eeeee-color) !important;
    min-height: auto !important;
  }

  .location-top {
    display: none;
  }

  .top-categories-search {
    left: 0;
    margin: auto;
    padding: 0 15px 8px;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
  }

  .container {
    max-width: 100%;
  }

  .main-nav-right .osahan-top-dropdown .dropdown-toggle {
    padding: 18px 16px !important;
  }

  .main-nav-right .osahan-top-dropdown img {
    left: auto;
    position: relative;
    top: 0;
  }

  .osahan-menu-2 .nav-link.shop {
    border-right: medium none;
    margin-right: 0;
  }

  .shop-detail-slider #sync2 {
    padding: 0;
  }

  .shop-detail-slider #sync2 img {
    margin: 26px 0 10px 0;
  }

  .navbar-toggler {
    background: var(--white-color) none repeat scroll 0 0 !important;
    border: medium none;
    border-radius: 2px;
    margin: 0 16px 0 0;
    padding: 6px 9px;
  }

  .top-categories-search-main {
    margin-top: 0 !important;
  }

  .main-nav-right .btn {
    font-size: 0;
    padding: 21px 15px;
  }

  .osahan-menu .my-2.my-lg-0 .main-nav-right {
    position: absolute;
    right: 76px;
    top: 0;
  }

  .main-nav-right .btn .mdi {
    font-size: 17px !important;
    line-height: unset !important;
    margin: 0;
    vertical-align: initial !important;
  }

  .login-modal-left {
    display: none;
  }

  .app img {
    margin-bottom: 4px;
  }

  .footer-social a {
    margin-bottom: 3px;
  }

  .top-categories-search .form-control {
    min-width: auto;
  }

  .top-category .owl-prev {
    left: 0;
  }

  .top-category .owl-next {
    right: 0px;
  }

  .owl-carousel-featured .owl-prev {
    left: 0;
  }

  .owl-carousel-featured .owl-next {
    right: 0px;
  }

  .feature-box {
    margin-bottom: 6px;
    overflow: hidden;
  }

  .top-categories-search .form-control-select,
  .top-categories-search .select2-container {
    width: 138px !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .location-top {
    display: none;
  }

  .top-categories-search {
    left: 0;
    margin: auto;
    padding: 0 15px 8px;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
  }

  .container {
    max-width: 100%;
  }

  .main-nav-right .osahan-top-dropdown .dropdown-toggle {
    padding: 18px 16px !important;
  }

  .main-nav-right .osahan-top-dropdown img {
    left: auto;
    position: relative;
    top: 0;
  }

  .osahan-menu-2 .nav-link.shop {
    border-right: medium none;
    margin-right: 0;
  }

  .shop-detail-slider #sync2 {
    padding: 0;
  }

  .shop-detail-slider #sync2 img {
    margin: 26px 0 10px 0;
  }

  .navbar-toggler {
    background: var(--white-color) none repeat scroll 0 0 !important;
    border: medium none;
    border-radius: 2px;
    margin: 0 16px 0 0;
    padding: 6px 9px;
  }

  .top-categories-search-main {
    margin-top: 0 !important;
  }

  .main-nav-right .btn {
    font-size: 0;
    padding: 21px 15px;
  }

  .osahan-menu .my-2.my-lg-0 .main-nav-right {
    position: absolute;
    right: 76px;
    top: 0;
  }

  .main-nav-right .btn .mdi {
    font-size: 17px !important;
    line-height: unset !important;
    margin: 0;
    vertical-align: initial !important;
  }

  .login-modal-left {
    display: none;
  }

  .app img {
    margin-bottom: 4px;
  }

  .footer-social a {
    margin-bottom: 3px;
  }

  .top-categories-search .form-control {
    min-width: auto;
  }

  .top-category .owl-prev {
    left: 0;
  }

  .top-category .owl-next {
    right: 0px;
  }

  .owl-carousel-featured .owl-prev {
    left: 0;
  }

  .owl-carousel-featured .owl-next {
    right: 0px;
  }

  .feature-box {
    margin-bottom: 6px;
    overflow: hidden;
  }

  .top-categories-search .form-control-select,
  .top-categories-search .select2-container {
    width: 138px !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .osahan-menu-2 .nav-link {
    font-size: 13px;
    padding: 13px 10px !important;
  }

  .main-nav-right .osahan-top-dropdown img {
    left: auto;
    position: relative;
    top: 0;
  }

  .main-nav-right .osahan-top-dropdown .dropdown-toggle {
    padding: 18px 16px !important;
  }

  .main-nav-right .btn {
    font-size: 0;
    padding: 21px 15px;
  }

  .main-nav-right .btn .mdi {
    font-size: 17px !important;
    line-height: unset !important;
    margin: 0;
    vertical-align: initial !important;
  }

  .location-top {
    display: none;
  }

  .top-categories-search .form-control {
    min-width: auto;
  }

  .top-category .owl-prev {
    left: 0;
  }

  .top-category .owl-next {
    right: 0px;
  }

  .owl-carousel-featured .owl-prev {
    left: 0;
  }

  .owl-carousel-featured .owl-next {
    right: 0px;
  }

  .top-categories-search {
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 16px;
    width: 45%;
  }

  .top-categories-search .form-control-select,
  .top-categories-search .select2-container {
    width: 138px !important;
  }

  .container {
    max-width: 100%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .top-categories-search .form-control {
    min-width: auto;
  }
}

.addQuantity {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  background-color: var(--primary-color);
  width: fit-content;
  padding: 0.1rem 0.7rem;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: var(--white-color);
  position: absolute;
  right: 10px;
  bottom: 20px;
  font-weight: 500;
}

.addQuantity_single {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  background-color: var(--primary-color);
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.1rem 0.7rem;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: var(--white-color);
  font-weight: 500;
}

.minplusBtn {
  cursor: pointer;
}

.orderTotal {
  font-weight: 500;
  color: var(--black-color);
  font-size: 16px;
}

.applypromo {
  text-align: center;
  margin-top: 0.5rem;
}

.applypromo p {
  color: var(--primary-color) !important;
  font-weight: 500;
  cursor: pointer;
}

.promoModal .modal-content {
  border-radius: 15px !important;
}

.promoModal .modal-header {
  border: none;
}

.promoItem {
  border-bottom: 1px solid var(--ced4da-color);
  padding-bottom: 0.8rem;
  margin-bottom: 0.8rem;
}

.promoItem h5 {
  margin: 0;
}

.promoDateail {
  margin-bottom: 0.5rem;
}

.applyPromo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.applyPromo p {
  font-size: 15px;
  font-weight: 500;
  border: 2px solid var(--primary-color);
  padding: 0.1rem 0.8rem;
  border-radius: 5px;
  margin: 0;
  color: var(--primary-color);
}

.applyPromo button {
  color: var(--primary-color);
  font-weight: 600;
  border: none;
  background-color: transparent;
  font-size: 15px;
}

.applyPromo2 button {
  color: var(--red-color) !important;
}

.footer-social a {
  margin: 5px;
}

.imagecss {
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0px;
  color: transparent;
}

.color_tras {
  color: transparent;
}

.width_set501 {
  width: 501px;
  margin-right: 16px;
}

.width_padding {
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  top: 0px;
  right: auto;
  left: 0px;
  width: calc(100% + 0px);
  padding: 0px;
  overflow-y: scroll;
}

.backcolor {
  background-color: var(--white-color);
}

.height58 {
  height: 58.539%;
}

.width100 {
  width: 100%;
}

.heightcal5 {
  height: calc(-5.35rem + 100vh);
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  /* background-image: url('../Assets/images/loader.gif');
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 11; */
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.otp_section,
.last_section {
  display: none;
}

.silder_imag {
  max-height: 353px;
}

.mt-31 {
  margin-top: 31px;
}

.input_field_custom3 {
  height: 35px !important;
  margin-bottom: 0.5rem;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.addressType {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  margin-top: 0.5rem;
}

.addressType button {
  width: 70px;
  border: 1px solid var(--f888-color);
  background-color: transparent;
  padding: 0.1rem 0.5rem;
  border-radius: 2px;
  outline: none;
}

.selectedADType {
  background-color: var(--primary-color) !important;
  color: var(--white-color);
  font-weight: 500;
  border-color: var(--primary-color) !important;
}

.wishlistBtn {
  border: none;
  background-color: transparent;
  outline: none !important;
}

.imageRed {
  display: none;
}

.wishlistBtn:hover .imageBlack {
  display: none;
}

.wishlistBtn:hover .imageRed {
  display: block !important;
}

.wishlistBtn img {
  width: 1.5rem;
}

.shop-detail-right_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shop-detail-right_head1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pac-container {
  z-index: 1051 !important;
}

.bg-black {
  background-color: var(--black-color) !important;
}

.bg-black p,
.bg-black a,
.bg-black h6,
.bg-black .text-dark {
  color: var(--white-color) !important;
}

.a.text-dark:focus,
a.text-dark:hover {
  color: var(--white-color) !important;
}

/* ------------------------------Header ------------------------------------------ */

.searchItems {
  width: 100%;
  z-index: 20;
  position: absolute;
  top: 40px;
  background-color: var(--white-color);
  border: 1px solid var(--ced4da-color);
  padding: 1rem 0.5rem;
}

.itemSearch img {
  width: 50px;
  height: 50px;
  border-radius: 2px;
}

.itemSearch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0.4rem 0.5rem;
  cursor: pointer;
}

.itemSearch:hover {
  background-color: var(--f9f9f9-color);
}

.itemSearch h6,
.itemSearch p {
  margin: 0;
}

/** invoice css **/
.top_rw {
  background-color: var(--fbfbfb-color);
}

.invoice-box {
  width: 498px;
  margin: auto;
  padding: 10px;
  border: 1px solid var(--eeeee-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: var(--sss555-color);
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
  border-bottom: solid 1px var(--dcdcdc-color);
}

.invoice-box table td {
  padding: 5px;
  vertical-align: middle;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: var(--f333-color);
}

.invoice-box table tr.information table td {
  padding-bottom: 0.7rem;
  padding-top: 0.7rem;
}

.invoice-box table tr.heading td {
  background: var(--eeeee-color);
  border-bottom: 1px solid var(--ced4da-color);
  font-weight: bold;
  font-size: 12px;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid var(--eeeee-color);
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid var(--eeeee-color);
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
    sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}

/** invoice css **/
.center_justify {
  justify-content: center;
}

.list-group-item-action {
  cursor: pointer;
}

.singlePRo {
  gap: 1rem !important;
  font-size: 15px !important;
}

.ellipsis-2-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

/* -------------------------------------Nav Buttons ----------------------------------------- */

/* Styling Next and Prev buttons */

.owl-theme .owl-nav button {
  background: var(--dcdcdc-color) none repeat scroll 0 0 !important;
  border-radius: 30px !important;
  color: var(--white-color) !important;
  display: inline-block !important;
  font-size: 21px !important;
  height: 40px !important;
  line-height: 40px !important;
  margin: 0 20px !important;
  opacity: 0.8 !important;
  text-align: center !important;
  text-transform: uppercase !important;
  width: 40px !important;
  outline: none !important;
}

.owl-theme .owl-nav button:hover {
  background: var(--a1c2224-color) none repeat scroll 0 0 !important;
  color: var(--white-color) !important;
}

.owl-prev {
  left: 0 !important;
  position: absolute !important;
  top: 37% !important;
  transition: all 0.45s cubic-bezier(0.165, 0.84, 0.44, 1) 0s !important;
  outline: none !important;
}

.owl-next {
  position: absolute !important;
  right: 0 !important;
  top: 37% !important;
  transition: all 0.45s cubic-bezier(0.165, 0.84, 0.44, 1) 0s !important;
  outline: none !important;
}

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-nav button:hover {
  /*IE7 fix*/
  opacity: 1 !important;
  text-decoration: none !important;
}

/* Styling Pagination*/

.owl-theme .owl-dots {
  display: inline-block !important;
  zoom: 1 !important;
  /*IE7 life-saver */
}

.owl-theme .owl-dots button {
  outline: none !important;
}

.owl-theme .owl-dot.active span {
  background-color: var(--f333-color) !important;
}

.owl-theme .owl-dots span {
  display: block !important;
  width: 32px !important;
  height: 3px !important;
  margin: 5px 2px !important;
  filter: Alpha(Opacity=50) !important;
  /*IE7 fix*/
  opacity: 0.5 !important;
  -webkit-border-radius: 2p !important;
  -moz-border-radius: 2px !important;
  border-radius: 2px !important;
  background: var(--a869791-color) !important;
}

.owl-theme .owl-dots.active span,
.owl-theme .clickable .owl-dots:hover span {
  filter: Alpha(Opacity=100) !important;
  /*IE7 fix*/
  opacity: 1 !important;
}

/* If PaginationNumbers is true */

.owl-theme .owl-dots span.owl-numbers {
  height: auto !important;
  width: auto !important;
  color: var(--white-color);
  padding: 2px 10px !important;
  padding: 2px 10px !important;
  font-size: 12px !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  border-radius: 30px !important;
}

.top-category .owl-stage-outer {
  text-align: -webkit-center;
}

.shop-filters .card-body {
  cursor: pointer !important;
}

.catagoryTag {
  background-color: var(--dark-blue);
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.catagoryTag h5 {
  margin: 0;
  color: var(--white-color);
}

.removePromo {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}

.headerStiky {
  position: sticky;
  top: 0;
  z-index: 10;
}

.centerImage {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logInBtn {
  pointer-events: none;
}

.logInBtnActive {
  pointer-events: all;
  color: var(--white-color) !important;
  background-color: var(--primary-color) !important;
}

.chooseImgBtn {
  border: 1px solid var(--black-color);
  border-radius: 5px;
  font-weight: 500;
  padding: 0.1rem 1rem;
  cursor: pointer;
}

.chooseImgBtn:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white-color);
}

.width50 {
  width: 50px;
}

.closeButtonModal {
  border: none;
  background-color: transparent;
}

.closeButtonModal img {
  width: 20px;
}
