* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --primary-color: #007bff;
  --secondary-color: #f6f5f5;
  --f9f9f9-color: #f9f9f9;
  --f888-color: #888;
  --f333-color: #333;
  --red-color: red;
  --success-color: #51aa1b;
  --black2224-color: #1c2224;
  --eeeee-color: #eeeeee;
  --white-color: #fff;
  --ced4da-color: #ced4da;
  --dcdcdc-color: #dcdcdc;
  --fbfbfb-color: #fbfbfb;
  --f384042-color: #384042;
  --ececec-color: #ececec;
  --black-color: #000000;
  --facebook-color: #395b9a;
  --google-color: #c71e25;
  --twitter-color: #3a9ed8;
  --instramm-color: #fcaf45;
  --whatapp-color: #25d366;
  --a1c2224-color: #1c2224;
  --a869791-color: #869791;
  --sss555-color: #555;
  --dark-blue: #2d3d54;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
